.areaItemContainer {
  margin: 1rem 1rem 1rem 1rem;
  border: 1px solid gray;
  padding: 0.5rem;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
}

.name {
  font-size: 16px;
  color: var(--secondary-color);
}

.address {
  font-size: 12px;
  color: var(--placeholder-color);
}

.nameCodeContainer {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.nameCode {
  font-size: 14px;
  color: white;
  border: 1px solid black;
  border-radius: 8px;
  padding: 3px 6px;
}