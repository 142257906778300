.Toastify__toast {
  font-family: 'DM Sans';
  box-shadow: 0px 4.3px 4.3px rgba(0, 0, 0, 0.25); 
  margin-bottom: 1px; 
  border-radius: 0;
}

.Toastify__toast-container--top-center {
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  transform: none !important;
}

.Toastify__toast-body {
  line-height: 1.125rem;
  font-size: 0.875rem;
}

.Toastify__toast-body div div:first-child{
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.313rem;
}

.Toastify__close-button {
  align-self: center;
  margin-right: 0.5rem;
}
.Toastify__close-button > svg {
  height: 1.25rem;
  width: 1.25rem;  
}

.Toastify__toast-theme--colored {
  border-left-width: 1rem;
  border-left-style: solid;    
}

.Toastify__toast-theme--colored.Toastify__toast--success{  
  border-left-color: #0B9444;
  background-color: #CEEADA;
  color: var(--secondary-color);
}

.Toastify__toast-theme--colored.Toastify__toast--success .Toastify__close-button > svg {
  fill: #0B9444;
}

.Toastify__toast-theme--colored.Toastify__toast--error{
  border-left-color: #BB4A48;
  background-color: #F1DBDA;
  color: var(--secondary-color);
}

.Toastify__toast-theme--colored.Toastify__toast--error .Toastify__close-button > svg {
  fill: #BB4A48;
}

.Toastify__toast-theme--colored.Toastify__toast--info{
  border-left-color: #2794B4;
  background-color: #D4EAF0;
  color: var(--secondary-color);
}

.Toastify__toast-theme--colored.Toastify__toast--info .Toastify__close-button > svg {
  fill: #2794B4;
}

.Toastify__toast-theme--colored.Toastify__toast--warning{
  border-left-color: #DFB104;
  background-color: #F9EFCD;
  color: var(--secondary-color);
}

.Toastify__toast-theme--colored.Toastify__toast--warning .Toastify__close-button > svg {
  fill: #DFB104;
}