.listContainer {
  z-index: 4;
  width: 100%;
  background: white;
  border-radius: 0px 0px 5px 5px;
  box-shadow: var(--common-box-shadow);    
}

.listItemContainer {
  display: flex;
  align-items: center;
  padding: var(--content-padding-vertical-medium) var(--content-padding-medium);  
  cursor: pointer;
}

.listItemContainer:hover {
  background-color: var(--background-color);
}

.listItemContainer:nth-child(1){
  border-top: 1px solid var(--border-color);
}

.loadingContainer {
  text-align: center;
  padding: var(--content-padding-vertical);
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: var(--content-padding-medium);
}

.mainText {
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1.1rem;
}

.secondaryText {
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--placeholder-color);
}

.singleLineTruncated {
  width: 80%;
}
.singleLineTruncated div {  
  display: block;
  white-space: nowrap; /* forces text to single line */
  overflow: hidden;
  text-overflow: ellipsis;
}
