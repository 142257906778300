.wrapper {
  top: 0;
  left: 0;
  width: 100%;
  position: relative;
  z-index: 1056; /* Hight z-index to override modal */
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--content-padding-horizontal) var(--content-padding-vertical);
  background-color: var(--brand-color);
  transition: all .5s ease-in-out;
}

.tmpText {
  font-size: 1.5rem;
  display: inline-block;
  color: white;
}
