.contentContainer { 
  padding: 1rem;
}

.priceContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;

  border-top: 1px solid #D5D6EA;
  border-left: 1px solid #D5D6EA;
  border-bottom: 1px solid #D5D6EA;
}

.priceButtonContainer{
  padding: 0;
}

.priceButton {
  padding: 0 1rem 1rem;
}

.button {
  width: 100%;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}