.contentContainer {
  padding: 1rem;
}
.text {
  color: var(--secondary-color);
  font-size: 0.9rem;
  margin-bottom: calc(var(--content-padding-vertical) * 2);
}

.button {
  width: 100%;
  margin-top: var(--content-padding-vertical);
}

