.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--content-padding);
  padding-top: 2rem;

  background: linear-gradient(0deg, white 50%, #EF827F 50%);
}

.content {
  border-radius: 12px;
  background: #FFFF;
  padding: 1.3rem 1.5rem;
  box-shadow: 0px 13px 40px 0px rgba(47, 48, 65, 0.15);
  max-width: 550px;
}

.justifyText {
  text-align: justify;
}

.btnContainer {
  margin-top: 1.3rem;
}

.btn {
  width: 100%;
}