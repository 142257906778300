.search {
  font-size: 1rem;
  padding: var(--content-padding-vertical-medium) var(--content-padding-horizontal);
  z-index: 4;
  box-shadow: 0px 4px 4px 0px #00000040;
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  width: 48px;
}

.search:focus {
  width: 100%;
  border-width: 0;
  box-shadow: var(--common-box-shadow); 
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out; 
  transition-delay: 50ms; 
}

.search:not(:focus) {
  background: url('../../../assets/images/search.png') no-repeat ;
  background-position: center right calc(var(--content-padding-horizontal) / 1.5);
  background-color: white;
  color: transparent;
  cursor: pointer;
}

.search:not(:focus) + div,
.search:not(:focus) ~ div:nth-of-type(2){  
  transition-delay: 250ms;
  transition-property: visibility;
  visibility: hidden;
}

.flatBottomBorder {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;  
}