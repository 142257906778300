.formContainer {
  margin-top: calc(var(--content-padding) / 2);
  padding: 1rem;
}
.cardElementWrapper {
  border: 1px solid #D5D6EA;
  border-radius: 0.3rem;  
  padding: 12px var(--content-padding-horizontal) 12px;  

  /* Floating label */
  position: relative;    
  transition: margin .2s;
}

.button {    
  width:100%;
  text-align: center;
}

.paymentProviderBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnImage {
  margin-right: calc(var(--content-padding-horizontal) / 4);
}

.actionRow {
  margin-bottom: var(--content-padding-vertical);
}

.noteContainer {
  border-radius: 8px;
  padding: 0.5rem;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-color: #cce1ff;
  /* background-color: var(--outline-color); */
  margin-bottom: 1rem;
  font-size: 0.9rem;
}
