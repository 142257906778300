.formGroup {
  position:relative;  
  transition: all 0.2s ease-out;
  height: 50px;   
}

.formGroup.hasError {
  height: 65px;
  transition: all 0.2s ease-out;
}

.label {
  position: absolute;
  top: 3px;
  left: calc(var(--content-padding-horizontal) / 2);
  padding: 8px;
  color: var(--placeholder-color);  
  font-weight: 400;
  transition: width 1s, top 0.2s, left 0.2s, padding 0.2s;  
  z-index: 9;  
}

.label.floating {
  top: -8px;
  left: calc(var(--content-padding-horizontal) - 5px);
  font-size:12px;
  width: fit-content;
  width: intrinsic; /* Safari only */
  padding: 0 5px;
  background: #fff;  
}

.hasError .label {
  color: var(--error-color)
}

.noBorder {  
  box-shadow: none !important;
}

.errorFeedback {
  display: block;
  margin-top: 0;
  padding-left: var(--content-padding-horizontal);  
}


.icon {
  position: absolute;
  top:10px;
  right: 10px;
  height: 1.8rem;
  width: 1.8rem;
  padding: 0.1rem;
  background-color: white;
}