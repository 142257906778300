.container {
  position: absolute;
  top: calc(-6.25rem / 2);
  left: calc(50% - 6.25rem / 2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 6.25rem;
  height: 6.25rem;
  width: 6.25rem;
  border: none;
}

.startBtn {
  background: linear-gradient(90deg, #245FCC 0%, #3478F6 9.09%, #245FCC 88.07%);
}

.stopBtn {
  background: linear-gradient(90deg, #569D4D 0%, #66C65A 9.09%, #448B3B 88.07%);
}

.disabledBtn {
  background: #CECECE;
}

.icon {
  height: 2rem;
  width: 2rem;
}

.label {
  font-size: 0.8rem
}
