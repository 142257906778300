.container {
  display: flex;
  flex-direction: column;
  padding: var(--content-padding);
  padding-top: 1rem;
}

.icon {
  height: 4rem;
  width: 4rem;
}

.card {
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 12px;
  background: #FFF;
  padding: 1.3rem 1.5rem;
  box-shadow: 0px 13px 40px 0px rgba(47, 48, 65, 0.15);
}

.text {
  margin: 1rem 0 1rem 0;
}