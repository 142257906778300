.container {
  background-color: var(--secondary-color);
  position: fixed;
  bottom:0;  
  width:50%;  
  z-index: 9999;
  color: #ffffff;
  border-top-left-radius: 6px;
}

.hidden {
  right:-50%;
  transition: right 0.3s ease-out;  
}

.visible {
  transition: right 0.3s ease-in;
  right:0;
}

.menuItem{
  display: flex;
  padding: calc(var(--content-padding-vertical) * 1.5) var(--content-padding-horizontal);
  cursor: pointer;
}

.menuItem:not(:last-child) {
  border-bottom: 1px solid #737373;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;  
}

.icon:not(:last-child){
  margin-right: var(--content-padding-horizontal);
}

.centered {
  justify-content: center;
}

.text {
  text-transform: uppercase;
  font-size: 0.8rem;
  line-height: 1.4rem;
}