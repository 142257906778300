/*
 * App uses css modules, each component will have css module that is scoped only for hat component and child elements.
 * In this file main css properties will be defined, like font sizes, spacings, colors, ...
 * All these properties can be used in other css modules and all chiled elements will inherit fromt it.
 */
.App {
  font-size: 16px;
  overscroll-behavior-y: none;
}

:root {
  /* Spacings in app */
  --content-padding: 1.1rem;
  --content-padding-medium: 0.9rem;

  --content-padding-vertical: 0.9rem;
  --content-padding-vertical-medium: 0.65rem;

  --content-padding-horizontal: 1.1rem;

  /* Bottom Map Toolbar */
  --mapToolbar-height: 4.2rem;

  /* App colors */
  --primary-gradient: linear-gradient(90.04deg, #3478F6 0.04%, #245FCC 99.97%);
  --secondary-gradient: linear-gradient(90deg, #66C65A 0%, #448B3B 88.07%);

  --brand-color: #EF827F;
  --primary-color: #3478F6;
  --secondary-color: #2F3041;

  --placeholder-color: #8C8C8C;
  --error-color: #dc3545;
  --outline-color: #86b7fe;
  --border-color: #e5e5e5;

  --control-border-radius: 5px;

  --background-color: #EDEDED;


  /** Shadows */
  --common-box-shadow: 0px 4px 4px 0px #00000040;
}

/** TODO: This should be on map file for css but selecting from aprent is not wroking so this is hack to hide bubble with informations on places */
.gm-style-iw-c,
.gm-style-iw-t::after {
  display: none;
}

/* Bootstrap */
.btn:focus {  
  box-shadow: none !important;
}
.form-control:focus {
  border-color: var(--outline-color) !important;
}
input:-internal-autofill-selected { 
  background-image: none !important;
  background-color: transparent !important;
  color: fieldtext !important;
}
  

/* Stripe */
.StripeElement--focus {
  border: 1px solid var(--outline-color) !important;
}

