.animatedPath {
  animation: draw 1.7s forwards infinite;
  animation-timing-function: linear;
  stroke-dashoffset: 500;
  stroke-dasharray: 500;
}

 @keyframes draw {    
  0% { stroke-dashoffset: 500; }
  45% { stroke-dashoffset: 250; }
  50% { stroke-dashoffset: 0; }
  50.1% { stroke-dashoffset: 250; }
  80% { stroke-dashoffset: 500; }
}
