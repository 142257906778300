.primary, .primary:hover {
  color: white;
  background: var(--primary-gradient);  
}

.secondary, .secondary:hover {
  color: white;
  background: var(--secondary-gradient);  
}

.primaryTransparent {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-color: transparent;
}

.secondaryTransparent {
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  background-color: transparent;
}


.regularTransparent{
  color: black;
  border: 1px solid #D5D6EA;
  background-color: transparent;
}
