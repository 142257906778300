.container {
  display: flex;
  flex-direction: column;
  padding: var(--content-padding);
  align-items: center;
}

.text {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 0.5rem;
  line-height: normal;
}

.buttonContainer {
  margin-top: 2rem;
}

.storeButton {
  height: 2.8rem;
}