.pricingsContainer{    
  height: 42px;  
}
.pricingTab {
  display: flex;
  background-color: var(--background-color);
  color: #6B6B6B;
  height: 42px;
  font-size: 0.9rem;  
  line-height: 18px;
  align-items: center;  
  padding: 0 var(--content-padding);
  justify-content: center;
}

.pricingTab:not(:last-child){
  border-right: 1px solid #fff;
}

.pricingTab.active {
  background-color: #fff;
  color: var(--secondary-color);
}

.pricingTab:not(.active){
  cursor: pointer;
}
