.container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--mapToolbar-height);
  display: flex;
  background-color: #333548;
  z-index: 1056; /* Hight z-index to override modal */
}

.logo {
  height: 2.5rem;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
}

.label {
  font-size: 0.8rem;
}
