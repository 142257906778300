.container {
  position: absolute;
  right: var(--content-padding-horizontal);
  bottom: calc(var(--mapToolbar-height) + var(--content-padding-horizontal));
}

.btn {
  padding: 0;
  width: 48px;
  height: 48px;
  display: flex;
  background: white;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px #00000040;
}
