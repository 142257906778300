.container {
  position: absolute;
  z-index: 9;    
  right: calc(var(--content-padding-horizontal) * 1.7);
  top: calc(var(--content-padding-horizontal) / 2);
  background: #fff;
  cursor: pointer;
}

.icon {
  fill: var(--secondary-color);
  height: 1.5rem;
  width: 1.5rem;
}
