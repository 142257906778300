.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--content-padding);
}

.image {
  height: 3rem;
  object-fit: contain;
  margin-bottom: 1rem;
}

.msgContainer {
  font-weight: 400;
  display: flex;
  color: var(--secondary-color);
  flex-direction: column;
}