.formContainer {
  margin-top: 0.5rem;
  padding: 1rem;
}

.infoText {
  font-size:0.9rem;
  margin-bottom: calc(var(--content-padding-vertical) * 1.5);
  color: var(--secondary-color);
}

.infoText a {
  color: var(--primary-color);
  text-decoration: none;
}