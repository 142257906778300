.container {
  display: inline-block;
  border: 1px solid white;
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem;
  color: white;
  font: 1rem;
}

.white {
  color: white;
  border-color: white;
}

.black {
  color: black;
  border-color: black;
}
