.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--content-padding);
  padding-top: 2rem;

  background: linear-gradient(0deg, white 50%, #EF827F 50%);
}

.title {
  color: #2F3041;
  font-size: 27px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.validationTitle {
  color: var(--secondary-color);
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
}

.icon {
  height: 0.9rem;
  width: 0.9rem;
  margin-right: 0.3rem;
}

.validationItem {
  color: var(--secondary-color);
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.formCard {
  border-radius: 12px;
  background: #FFF;
  padding: 1.3rem 1.5rem;
  box-shadow: 0px 13px 40px 0px rgba(47, 48, 65, 0.15);
}