.container {  
  position:relative;
  background-color: #fff;
  height: 100vh;  
}

.loading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size:1.5rem;
}

.dotsWrapper {
  float:right;
  margin-left:0.2em;  
}

.dots{  
  animation: dots 3s infinite;
  overflow: hidden;  
  letter-spacing: 5px;  
}

@keyframes dots {
  0%, 20% {width: 30%;}      
  40% { width: 66%}  
  80%, 100% {width: 100%;}
}


.logo {
  position: absolute;
  bottom: var(--content-padding-vertical);
  left: 50%;
  transform: translateX(-50%);
  width: 75px;
  fill: var(--brand-color);
}
