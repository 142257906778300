.container {
  margin: var(--content-padding-vertical) 0;
  position: relative;
  border: 1px solid #E3E3E3;
  border-radius: var(--control-border-radius);
  padding: calc(var(--content-padding) / 2);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
}

.label {
  position: absolute;
  top: -10px;
  font-size: 12px;
  padding: 0 4px;
  color: #767676;
  background-color: #fff;
  z-index: 2;
}

.actionBtn {
  cursor: pointer;
  width: 10%;
  max-width: 20px;
  margin-top: -2px;
  text-align: center;
  user-select: none;
}

.actionBtn.muted {
  cursor: default;
}

.muted svg {
  fill: #F5F5F5;
  transition: fill 0.3s;
}

.actionBtnIcon {
  height: 20px;
  width: 20px;
  fill: #cecece;
}

.listWrapper {
  position: relative;
  width: 100%;
  height: 2rem;
  overflow: hidden;
  display: flex;
}

.list {
  position: absolute;
  transition: left 0.5s;
  width: max-content;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  height: 2rem;
  float: left;
  text-align: center;
  font-size: 1.2rem;
  color: #CECECE;
  transition: font-size 0.3s, color 0.3s;
  user-select: none;
}

.selectedOption {
  font-size: 1.7rem;
  color: var(--secondary-color);
  transition: font-size 0.4s, color 0.4s;
}

.selectedOptionBackground {
  position: absolute;
  top: -0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  background-color: #f0f1f2;
}

.nextOption {
  font-size: 1.4rem;
  color: #8C8C8C;
  transition: font-size 0.3s, color 0.3s;
}