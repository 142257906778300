.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 2;
  width: 100%;
  padding: 0 var(--content-padding-horizontal);
  margin-top: var(--content-padding-vertical);
}

.closeBtnWrapper{
  display: none;
}

.blurContainer {
  transition: all 0.5s;
}

.blurContainerFocused {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: rgba( 0, 0, 0, 0.35);
  transition: all 0.5s;
}


