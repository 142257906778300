.container {
  position: relative;
  margin-top: var(--content-padding-vertical);
}
.button {  
  border: 1px solid #E3E3E3;
  border-radius: var(--control-border-radius);
  transition: none;
}

.label {
  position: absolute;
  top: -10px;
  color: var(--placeholder-color);
  font-size: 0.8rem;
  background-color: #ffffff;
  margin-left: var(--content-padding-horizontal);
  padding: 0 calc(var(--content-padding-horizontal) / 4);
  z-index:2;
}

.icon {
  margin-right: calc(var(--content-padding-horizontal) / 4);
}

.title {
  display: grid;
  align-content: center;
  margin-left: calc(var(--content-padding-horizontal) / 4);
  height: 1.7rem;
}

.chevronWrapper {
  position: absolute;
  top: 50%;  
  transform: translateY(-50%);
  right: var(--content-padding-horizontal);
}

.chevronIcon {  
  height: 1.2rem;
  width: 1.2rem;  
  fill: var(--secondary-color);
}

.chevronIconDown {
  transform: rotate(-90deg);
}

.chevronIconUp {
  transform: rotate(90deg);
}

.expanded {
  border-bottom: 1px solid transparent;  
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: none;
}

.list {
  position: absolute;
  width: 100%;
  background-color: #fff;;
  border: 1px solid var(--border-color);
  border-top: none;
  border-bottom-left-radius: 5px;  
  border-bottom-right-radius: 5px;  
}

.item {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  padding: var(--content-padding-vertical) var(--content-padding-horizontal);  
}

.item:hover {
  cursor:pointer;
}

.selected {
  border-bottom: 1px solid var(--border-color);
}

.placeholder {
  color: var(--placeholder-color);
}